import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { RequestManagerWrapper } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';

import SchoolForm from './components/SchoolForm';
import { redirectCsrCreatedUser } from '@utils';
import { FCWithChildren } from '@types';

const Main: FCWithChildren<{}> = () => {
  const { isMobile } = useLayoutContext();
  const [campusType, setCampusType] = useState();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    redirectCsrCreatedUser(window.location.search, history);
  }, []);

  const formStyle = classNames(
    'w-full md:w-4/6 lg:w-4/6 xl:w-1/2 m-4 p-6 space-y-4 flex flex-col bg-white',
    {
      'border shadow-md': !isMobile,
    },
  );

  return (
    <section className="m-4 flex flex-col items-center">
      <h1 className="text-3xl mt-16 mb-4 font-light">Tell us where you&apos;ll be living</h1>
      <div className={formStyle}>
        <RequestManagerWrapper loadingContainer>
          <SchoolForm isMobile={isMobile} setCampusType={setCampusType} />
        </RequestManagerWrapper>
      </div>
      {campusType && (
        <div className="flex flex-col justify-center items-center my-2 w-full md:w-4/6 lg:w-4/6 xl:w-1/2">
          <section className="w-full flex flex-col items-center overflow-hidden my-2 sm:pl-4 lg:my-2">
            <a href="//www.boldyn.com" className="underline">
              Learn more about Boldyn Networks
            </a>
          </section>
        </div>
      )}
    </section>
  );
};

export default Main;
