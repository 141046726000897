import { FCWithChildren } from '@types';
import React from 'react';
import { UserInfoProvider } from '@contexts/UserInfoContext';
import { FieldErrors } from 'react-hook-form';
import UserInfoSimpleWrapper from '@pages/UserInfo/UserInfoSimpleWrapper';

export type Dropdown = {
  label: string;
  value: string;
  message?: string;
};
export type ErrorType = {
  status: string;
  data: {
    error?: string;
    errors?: Record<string, string[]>;
  };
};
export type FormData = {
  accountType?: any;
  building?: Dropdown;
  customerId?: number;
  email?: string;
  emailConfirmation?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string | null;
  question?: Dropdown;
  recoveryAnswer?: string;
  room?: string;
  pan_room?: Dropdown;
  termsAndConditions?: boolean;
  username?: string;
};
export type OnErrorFunction = (errors: FieldErrors<FormData>) => void;
export type UserInfoUrls = {
  ACCOUNT: string;
  DEFAULT: string;
  LOCATION: string;
  LOGIN: string;
  PERSONAL: string;
  SIMPLE: string;
};
type UserInfoProps = {
  csrToken?: string;
  isCsrCreated?: boolean;
  isRegistering?: boolean;
  path: string;
};

const UserInfo: FCWithChildren<UserInfoProps> = ({
  csrToken = '',
  isCsrCreated = false,
  isRegistering = false,
  path,
}) => (
  <UserInfoProvider>
    <UserInfoSimpleWrapper
      csrToken={csrToken}
      isCsrCreated={isCsrCreated}
      isRegistering={isRegistering}
    />
  </UserInfoProvider>
);

export default UserInfo;
