import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolByDomain } from 'sdk-apogee';
import { getSubdomain } from '@utils';

const SUBDOMAIN = getSubdomain();
const usePageTitle = (title?: string) => {
  const { data } = useQuery({
    queryKey: ['school', SUBDOMAIN],
    queryFn: () => getSchoolByDomain({ domain: SUBDOMAIN }),
    enabled: false,
  });
  useEffect(() => {
    const siteTitle = 'Boldyn Networks';
    document.title = !title ? siteTitle : [title, siteTitle].join(' - ');
    return () => {
      document.title = siteTitle;
    };
  }, [data?.type, title]);
};

export default usePageTitle;
