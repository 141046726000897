import { FCWithChildren } from '@types';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import apogeeLogo from '@assets/images/apogee-logo.png';
import campusConnectLogo from '@assets/images/CampusConnect-Logo.png';
import boldynLogo from '@assets/images/Boldyn_logo_white_transp_RGB.png';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';

import AdminNavigation from './AdminNavigation';
import MobileNavigation from './MobileNavigation';
import PublicNavigation from './PublicNavigation';
import SchoolNavigation from './SchoolNavigation';
import useWindowSize from '@hooks/useWindowSize';
import {
  Button,
  CompleteUserInfoBanner,
  OutageNotifications,
  RequestManagerWrapper,
} from '@components';
import styled from 'styled-components';

type HeaderProps = {
  admin?: boolean;
  isBoldynRoute: boolean;
  publicRoute: boolean;
};

const SkipLinkWrapper = styled.div`
  a:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  a:focus {
    background-color: #f2f2f2;
    border: 1px solid #3182ce;
    color: #3182ce;
    box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
`;

const Header: FCWithChildren<HeaderProps> = ({ admin = false, isBoldynRoute, publicRoute }) => {
  const history = useHistory();
  const { isMobile, isBoldyn, logo, logoText, type } = useLayoutContext();
  const { navBarRef } = useAppContext();
  const { innerWidth } = useWindowSize();
  const showResnetLogo = () => type === 'Property';
  const logoImgClass = classNames(
    innerWidth <= 640 ? 'h-8' : 'h-16',
    'cursor-pointer mx-1 sm:mx-2',
  );
  const apogeeImgClass = classNames(innerWidth <= 640 ? 'h-4' : 'h-8', 'cursor-pointer');
  const canDisplayLogo = (publicRoute && !isBoldynRoute) || (!publicRoute && !isBoldynRoute);
  const boldynImgClass = classNames(!canDisplayLogo && 'ml-6', 'h-8', 'cursor-pointer');
  const Brand = {
    APOGEE: 'APOGEE',
    BOLDYN: 'BOLDYN',
    CAMPUS_CONNECT: 'CAMPUS_CONNECT',
  };
  const branding = useMemo(() => {
    if (isBoldyn) {
      return Brand.BOLDYN;
    } else if (type === 'Property') {
      return Brand.CAMPUS_CONNECT;
    }
    return Brand.APOGEE;
  }, [type, isBoldyn]);
  const separatorClass = classNames(innerWidth <= 640 ? 'h-6' : 'h-8', 'border-r border-gray-200', {
    'mx-1': branding === Brand.APOGEE,
    'ml-1 mr-5': branding === Brand.BOLDYN,
    'ml-1 mr-3': branding === Brand.CAMPUS_CONNECT,
  });
  const BrandLogo = () => {
    switch (branding) {
      case Brand.BOLDYN:
        return <img className={boldynImgClass} src={boldynLogo} alt="Boldln Networks" />;
      case Brand.CAMPUS_CONNECT:
        return <img className={apogeeImgClass} src={campusConnectLogo} alt="Campus Connect" />;
      case Brand.APOGEE:
      default:
        return <img className={apogeeImgClass} src={apogeeLogo} alt="Apogee" />;
    }
  };

  const handleLogoClick = () =>
    isBoldynRoute ? (document.location.href = '//www.boldyn.com') : history.push('/home');

  return (
    <>
      <div className="w-screen h-20 px-6 lg:px-56 xl:px-56 flex" ref={navBarRef} />
      <header className="fixed w-screen h-20 z-50 pl-0 pr-4 sm:px-6 lg:px-40 xl:px-40 flex bg-primary-background">
        <SkipLinkWrapper id="skip-to-content">
          <a href="#main-content">Skip to Main Content</a>
        </SkipLinkWrapper>
        <div className="flex items-center w-6/12 sm:2/3 md:w-1/2">
          <Button
            className="flex items-center"
            onClick={handleLogoClick}
            layout="link"
            size="noPadding"
            title="Homepage"
          >
            {canDisplayLogo ? (
              <>
                {logo?.split('/').pop() !== '' ? (
                  <img className={logoImgClass} src={logo} alt={logoText ? logoText : 'LOGO'} />
                ) : (
                  <>
                    <div className="flex items-center cursor-pointer">
                      <span className="text-white text-3xl font-normal pr-3">
                        {logoText ? logoText : 'LOGO'}
                      </span>
                    </div>
                  </>
                )}
                <div className={separatorClass} style={{ width: '0.15rem' }} />
              </>
            ) : null}
            <BrandLogo />
          </Button>
        </div>

        <div className="flex items-center w-6/12 sm:1/3 md:w-1/2">
          {isMobile || innerWidth <= 640 ? (
            <MobileNavigation publicRoute={publicRoute} />
          ) : publicRoute ? (
            <PublicNavigation />
          ) : admin ? (
            <AdminNavigation />
          ) : (
            <SchoolNavigation />
          )}
        </div>
      </header>
      <CompleteUserInfoBanner />
      {!isBoldynRoute && (
        <RequestManagerWrapper>
          <OutageNotifications />
        </RequestManagerWrapper>
      )}
    </>
  );
};

export default Header;
