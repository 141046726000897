import { FCWithChildren } from '@types';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getDeviceStatus } from 'sdk-apogee';

import { omit } from 'ramda';

import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';
import { getSubdomain } from '@utils';
import { useUserInfoContext } from '@contexts/UserInfoContext';
import classNames from 'classnames';
import { isValidPhoneNumber } from 'react-phone-number-input';
import UserInfoSimpleForm from '@pages/UserInfo/UserInfoSimpleForm';

type UserInfoSimpleWrapperProps = {
  csrToken?: string;
  isCsrCreated?: boolean;
  isRegistering?: boolean;
};

const UserInfoSimpleWrapper: FCWithChildren<UserInfoSimpleWrapperProps> = ({
  csrToken = '',
  isCsrCreated = false,
  isRegistering = false,
}) => {
  const history = useHistory();
  const location: any = useLocation();
  const { isAuthenticated } = useAppContext();
  const { loading, userInfo, setHasExistingData, setLoading, setUserInfo } = useUserInfoContext();
  const { buildings, isMobile, largeHeroImage, authType, isSso } = useLayoutContext();
  const SUBDOMAIN = getSubdomain();
  const building = useMemo(() => {
    const formattedBuildings = buildings
      .filter((b) => b.isActive)
      .map(({ id, name }) => ({ label: name, value: id }));
    if (formattedBuildings.length === 1) {
      return formattedBuildings[0];
    }
    return undefined;
  }, [buildings]);

  useEffect(() => {
    if (authType === 'nonsso' && sessionStorage.getItem('mac')) {
      getDeviceStatus({
        domain: SUBDOMAIN,
        macAddress: sessionStorage.getItem('mac') || '',
      }).then((res) => {
        if (res?.userType) history.push('/device-confirm');
      });
    }
  }, []);

  const validPhone = (phone = '') =>
    phone &&
    ((isValidPhoneNumber(phone) && phone) ||
      (isValidPhoneNumber(`+${phone}`) && `+${phone}`) ||
      (isValidPhoneNumber(`+1${phone}`) && `+1${phone}`) ||
      '');

  useEffect(() => {
    const data = location?.state?.data || {};
    const ssoUserData: any = omit(['first', 'last'], data);
    setHasExistingData(!!location?.state?.data);
    setUserInfo({
      ...userInfo,
      ...ssoUserData,
      building: ssoUserData.building || userInfo.building || building,
      token: csrToken,
      phone: validPhone(data.phone),
      firstName: data.first || data.firstName,
      lastName: data.last || data.lastName,
      emailConfirmation: data.email,
      accountType: data.accountType || data.type,
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (location.state?.data) return;

    if (isAuthenticated) {
      history.push('/home');
    } else if (isRegistering && isSso) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegistering, authType, history, isAuthenticated]);

  const signUpStyle = classNames('w-full bg-white px-4 lg:w-1/2 xl:w-1/3', {
    'border shadow-md relative -top-14': !isMobile,
  });

  const landingContainerCls = classNames(
    '-mx-40 h-64 bg-cover bg-center',
    largeHeroImage ? 'bg-cover bg-center text-white' : 'bg-gray-300 text-black',
  );

  return loading ? null : (
    <>
      {!isMobile ? (
        <div
          className={landingContainerCls}
          style={{ backgroundImage: `url(${largeHeroImage})` }}
        />
      ) : null}
      <div className="flex justify-end w-full">
        <section className={signUpStyle}>
          <UserInfoSimpleForm isCsrCreated={isCsrCreated} isRegistering={isRegistering} />
        </section>
      </div>
    </>
  );
};

export default UserInfoSimpleWrapper;
