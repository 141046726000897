"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMobileReachableEnv = exports.MOBILE_ENVS = void 0;
exports.MOBILE_ENVS = {
    APOGEE: 'https://api-campus.apogee.us',
    APOGEE_TEST: 'https://api-campus.beta.hed.boldyn.com',
    APOGEE_STAGE: 'https://api-campus.stage.console.apogee.us',
};
var getRequest = function (env) { return fetch("".concat(env, "/api/v1/schools")); };
var getMobileReachableEnv = function () {
    if (global.MOBILE) {
        var timeout_1 = new Promise(function (resolve, reject) {
            setTimeout(reject, 2000, 'Request timed out');
        });
        global.REACHABLE_ENV = [];
        Promise.race([timeout_1, getRequest(exports.MOBILE_ENVS.APOGEE)])
            .then(function () {
            global.REACHABLE_ENV.push(exports.MOBILE_ENVS.APOGEE);
            Promise.race([timeout_1, getRequest(exports.MOBILE_ENVS.APOGEE_TEST)])
                .then(function () {
                global.REACHABLE_ENV.push(exports.MOBILE_ENVS.APOGEE_TEST);
                Promise.race([timeout_1, getRequest(exports.MOBILE_ENVS.APOGEE_STAGE)]).then(function () {
                    global.REACHABLE_ENV.push(exports.MOBILE_ENVS.APOGEE_STAGE);
                });
            })
                .catch(function () {
                console.log('timedOut');
                Promise.reject();
            });
        })
            .catch(function () {
            global.REACHABLE_ENV.push(exports.MOBILE_ENVS.APOGEE_TEST);
        });
    }
};
exports.getMobileReachableEnv = getMobileReachableEnv;
