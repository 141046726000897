import { FCWithChildren } from '@types';
import React, { useContext, useEffect } from 'react';

import { Button } from '@components';

import { MdLiveHelp } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { AppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';
// @ts-ignore
import ApogeeEuaPdf from '@assets/documents/apogee-end-user-agreement.pdf';
import { openChat } from '@utils';

const Footer: FCWithChildren = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { isAuthenticated } = useContext(AppContext);
  const { type } = useLayoutContext();
  const getSupportPageRedirectUrl = () => (isAuthenticated ? '/faq' : '/support');
  const isPropertyType = type === 'Property';
  const isSelectPage = pathname === '/select';
  const year = moment().year();

  useEffect(() => {
    // TODO: make sure to refactor this code after we get service now chat integrated
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('chat') === 'open') {
      setTimeout(openChat, 200);
    }
  }, []);

  return (
    <footer
      role="contentinfo"
      className="flex flex-col w-full mt-auto overflow-hidden bg-gray-200 px-4 sm:px-6 lg:px-40 xl:px-40"
    >
      <div className="flex justify-between flex-col sm:flex-row">
        {!isSelectPage ? (
          <div className="flex items-start w-full sm:w-1/3 justify-start my-5">
            <div className="flex font-semibold">
              <Button
                layout="link"
                size="noPadding"
                className="w-full mr-4 flex font-semibold"
                onClick={openChat}
              >
                <div className="flex items-center">
                  <MdLiveHelp className="text-lg text-black mr-1" />
                  <span className="text-sm text-black font-semibold">Live Chat</span>
                </div>
              </Button>
              <Button
                layout="link"
                size="noPadding"
                className="w-1/2 mr-4 flex font-semibold"
                onClick={() => {
                  history.push(getSupportPageRedirectUrl());
                }}
              >
                <div className="flex items-center">
                  <BsFillQuestionCircleFill className="text-base text-black mr-1" />
                  <span className="text-sm text-black font-semibold">Support</span>
                </div>
              </Button>
            </div>
          </div>
        ) : null}
        <div className="my-5 text-xs">
          © Copyright {year}. Boldyn Networks. All rights reserved.{' '}
          {isPropertyType
            ? 'Residential networks for student housing.'
            : 'Residential student networks for colleges and universities.'}
        </div>
      </div>

      <div className="flex flex-col items-start my-2 space-x-2 sm:flex-row sm:justify-center sm:my-8 divide-x divide-gray-800">
        <div className="text-sm underline">
          <a
            className="text-black border-2 border-transparent focus:border-blue-700"
            href={ApogeeEuaPdf}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </div>

        <div className="text-sm underline pl-2">
          <a
            className="text-black border-2 border-transparent focus:border-blue-700"
            href="https://www.boldyn.com/us/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
