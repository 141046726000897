import React, { useMemo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { createProfile, IAccount, service } from 'sdk-apogee';

import { omit } from 'ramda';

import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useToasts } from 'react-toast-notifications';

import { Button, HelperText, Input, Label, ModalAccountFound, ModalWifiEnabled } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';
import useUpdateProfile from '@hooks/useUpdateProfile';
import { parseError, stripCountryCode } from '@utils';
import { UserInfoType, useUserInfoContext } from '@contexts/UserInfoContext';
import { Dropdown, ErrorType } from '@pages/UserInfo/UserInfo';
import Select from 'react-select';
// @ts-ignore
import ApogeeEuaPdf from '@assets/documents/apogee-end-user-agreement.pdf';
import { FCWithChildren, NumericDropdownDefault } from '@types';
import usePanRooms from '@hooks/usePanRooms';
import usePageTitle from '@hooks/usePageTitle';

type UserInfoSimpleFormProps = {
  isCsrCreated?: boolean;
  isRegistering?: boolean;
};

type TAuthenticationParams = {
  account?: IAccount;
  token: string;
};

export type FormData = {
  accountType?: any;
  building?: Dropdown;
  customerId?: number;
  email?: string;
  emailConfirmation?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string | null;
  question?: Dropdown;
  recoveryAnswer?: string;
  room?: string;
  pan_room?: Dropdown;
  termsAndConditions?: boolean;
  username?: string;
};

const UserInfoSimpleForm: FCWithChildren<UserInfoSimpleFormProps> = ({
  isCsrCreated = false,
  isRegistering = false,
}) => {
  usePageTitle('Create Account');
  const history = useHistory();
  const { user, saveUser, setIsAuthenticated } = useAppContext();
  const {
    buildings,
    clientUserTypes,
    customerId,
    roomBasedPanEnabled,
    type,
    logInRedirect,
    hasMultipleSites,
    isSam,
    passwordLabel,
  } = useLayoutContext();
  const { hasExistingData, userInfo, setUserInfo } = useUserInfoContext();

  const [usernameReadOnly, setUsernameReadOnly] = useState(isCsrCreated && !!userInfo.username);
  const [userInfoFormErrors, setUserInfoFormErrors] = useState<Record<string, string[]>>({});
  const [isWifiEnabledModalOpen, setIsWifiEnabledModalOpen] = useState(false);
  const [isAccountFoundModalOpen, setIsAccountFoundModalOpen] = useState(false);
  const [authenticationParams, setAuthenticationParams] = useState<TAuthenticationParams>({
    account: undefined,
    token: '',
  });
  const [preWiredKey, setPreWiredKey] = useState('');
  const { addToast } = useToasts();
  const isPropertyType = type === 'Property';
  const activeClientUserTypes = useMemo(
    () =>
      (Array.isArray(clientUserTypes) &&
        clientUserTypes.filter((t) => t.isUserSelectable && t.isActive)) ||
      [],
    [clientUserTypes],
  );
  const BasicFields = ['accountType', 'termsAndConditions'];
  const RegOnlyFields = ['email', 'username', 'password'];
  const UserInfoFormFields = !isRegistering ? BasicFields : [...BasicFields, ...RegOnlyFields];
  const handelAccountFoundError = (message: string) => {
    if (message.match(/An account with this (email address|username) already exists/)) {
      setIsAccountFoundModalOpen(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfoFormErrors && Object.keys(userInfoFormErrors).length) {
      Object.keys(userInfoFormErrors).forEach((key) => {
        const errorMessages = userInfoFormErrors[key as keyof typeof userInfoFormErrors];

        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((message: string) => {
            setError(key as keyof FormData, { type: 'server', message });
          });
        }
      });
    }
  }, []);

  const formattedBuildings = useMemo(
    () =>
      buildings.filter((b) => b.isActive).map(({ id, name }) => ({ label: name, value: `${id}` })),
    [buildings],
  );

  const handleSuccess = (account: IAccount) => {
    addToast('Profile updated successfully', { appearance: 'success' });
    const token = account.authToken;
    if (!token) {
      history.push('/', {
        state: {
          isUserCreated: true,
          preWiredKey: account.preWiredKey,
        },
      });
    } else {
      setPreWiredKey(account.preWiredKey);
      setAuthenticationParams({ account, token });
      setIsWifiEnabledModalOpen(true);
    }
  };

  const handleError = (apiError: ErrorType) => {
    const errors = apiError?.data?.errors;
    if (errors && Object.keys(errors).length) {
      onErrorCreatingUser(errors);
    } else if (apiError?.data?.error) {
      const error = parseError(apiError?.data?.error);
      try {
        if (error) {
          const errors = JSON.parse(`{${error}}`);
          onErrorCreatingUser(errors);
        }
      } catch (e) {
        addToast(parseError(error), {
          appearance: 'error',
        });
      }
    } else {
      addToast(isRegistering ? 'Unknown error creating user' : 'Unknown error updating profile', {
        appearance: 'error',
      });
    }
  };

  const {
    update,
    isPending: saveQueryLoading,
    error: updateProfileError,
  } = useUpdateProfile({
    id: (isCsrCreated ? userInfo?.userId : user?.id) as number,
    csrCreated: isCsrCreated,
    handleSuccess: (response) => {
      handleSuccess(response.data);
    },
  });

  useEffect(() => {
    if (updateProfileError) {
      handleError(updateProfileError);
    }
  }, [updateProfileError]);

  const COMPLETE_PROFILE_SCHEMA = yup.object().shape({
    building: yup
      .object()
      .when([], {
        is: () => hasMultipleSites,
        then: () =>
          yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string().required('Please select a building'),
            })
            .required('Please select a building'),
      })
      .nullable(),
    accountType: yup
      .string()
      .when([], {
        is: () => {
          return !userInfo.group;
        },
        then: () => yup.string().required('Account Type required'),
      })
      .nullable(),
    termsAndConditions: yup.boolean().oneOf([true], 'You must accept the Terms and Conditions'),
  });

  const CREATE_PROFILE_SCHEMA = yup.object().shape({
    username: yup
      .string()
      .email('Must be a valid Email Address')
      .required('Email Address required'),
    password: yup
      .string()
      .required(`${passwordLabel} is required`)
      .test({
        name: 'valid',
        params: { passwordLabel },
        message: `${passwordLabel} is case sensitive and must contain at least eight characters`,
        test: (val) => (val ? val.length >= 8 : true),
      }),
    building: yup
      .object()
      .when([], {
        is: () => hasMultipleSites,
        then: () =>
          yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string().required('Please select a building'),
            })
            .required('Please select a building'),
      })
      .nullable(),
    pan_room: yup
      .object()
      .when([], {
        is: () => roomBasedPanEnabled,
        then: () =>
          yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string().required('Please select a room'),
            })
            .required('Please select a room'),
      })
      .nullable(),
    accountType: yup.string().required('Account Type is required').nullable(),
    termsAndConditions: yup.boolean().oneOf([true], 'You must accept the Terms and Conditions'),
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ...userInfo,
      accountType:
        !userInfo.group && activeClientUserTypes.length === 1
          ? activeClientUserTypes[0].name
          : userInfo.accountType,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(isRegistering ? CREATE_PROFILE_SCHEMA : COMPLETE_PROFILE_SCHEMA),
  });

  const fetchPanRooms = (selectedBuilding: NumericDropdownDefault) => {
    clearErrors('pan_room');
    getPanRooms(selectedBuilding?.value).then((response) => {
      if (selectedBuilding?.value && Array.isArray(response) && !response.length) {
        // @ts-ignore
        setValue('pan_room', '');
        setError('pan_room', {
          type: 'manual',
          // @ts-ignore Using JSX works, but typed as string.
          message: (
            <>
              {selectedBuilding?.label} doesn't have any rooms assigned. Select another building or{' '}
              <Link to={'/support'}>contact support</Link>.
            </>
          ),
        });
      } else if (selectedBuilding?.value) {
        trigger('pan_room');
      }
    });
  };

  // @ts-ignore
  const selectedBuilding = useWatch({ control, name: 'building' }) as NumericDropdownDefault;

  const { getPanRooms, panRooms, panRoomOptions } = usePanRooms({
    onError: () => {
      if (selectedBuilding?.value) {
        // @ts-ignore
        setValue('pan_room', '');
        setError('pan_room', {
          type: 'manual',
          // @ts-ignore Using JSX works, but typed as string.
          message: (
            <>
              We are having trouble finding rooms for the {selectedBuilding?.label} building. You
              can{' '}
              <button className="text-blue-500" onClick={() => fetchPanRooms(selectedBuilding)}>
                try again
              </button>{' '}
              or select another building. Please{' '}
              <Link to={'/support'} className="text-blue-500">
                contact support
              </Link>{' '}
              if the problem persists.
            </>
          ),
        });
      }
    },
  });

  useEffect(() => {
    if (roomBasedPanEnabled) {
      fetchPanRooms(selectedBuilding);
    }
  }, [selectedBuilding, roomBasedPanEnabled]);

  const onErrorCreatingUser = (errors: Record<string, string[]>) => {
    addToast('Check one or more fields', { appearance: 'error' });
    setUserInfoFormErrors(errors);
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((key) => {
        const error = errors[key as keyof typeof errors];
        if (Array.isArray(error)) {
          errors[key as keyof typeof errors].forEach((message: string) => {
            const mutatedKey = ['email'].includes(key) ? 'username' : key;
            // @ts-ignore
            setError(mutatedKey, { type: 'server', message });
            handelAccountFoundError(message);
          });
        } else {
          const mutatedKey = ['email'].includes(key) ? 'username' : key;
          // @ts-ignore
          setError(mutatedKey, { type: 'server', message: `${errors[key]}` });
          handelAccountFoundError(`${errors[key]}`);
        }
      });
    }
  };
  const [prevUserInfoUpdates, setPrevUserInfoUpdates] = useState(userInfo);
  const userInfoUpdates = watch();

  useEffect(() => {
    if (JSON.stringify(userInfoUpdates) !== JSON.stringify(prevUserInfoUpdates)) {
      setUserInfo({ ...userInfo, ...userInfoUpdates });
      setPrevUserInfoUpdates(userInfoUpdates as UserInfoType);
    }
  }, [userInfoUpdates, prevUserInfoUpdates]);
  const authenticate = ({ account, token }: TAuthenticationParams) => {
    setIsAuthenticated(true);
    service.interceptors.request.use((config: any) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }));
    saveUser(account);
    localStorage.setItem('token', token);
  };

  const { mutate: create, isPending } = useMutation({
    mutationFn: (data: Partial<FormData>) => createProfile(data as any),
    onSuccess: (account) => {
      handleSuccess(account);
    },
    onError: (apiError: ErrorType) => {
      handleError(apiError);
    },
  });

  const isValid = (input: string, isController: boolean = false): boolean =>
    isController ? !(errors as any)[input]?.value?.message : !(errors as any)[input]?.message;

  useEffect(() => {
    if (!isValid('username')) {
      setUsernameReadOnly(false);
    }
  }, [isValid('username')]);

  const onSubmit = (formData: FormData) => {
    const data = { ...userInfo, ...formData };
    const values = {
      ...data,
      email: isRegistering ? data.username : data.email,
      emailConfirmation: isRegistering ? data.username : data.email,
      pan_room: !roomBasedPanEnabled
        ? undefined
        : {
            id: data?.pan_room?.value,
            name: data?.pan_room?.label,
          },
      phone: data.phone ? stripCountryCode(data.phone) : '',
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      room: roomBasedPanEnabled ? undefined : data.room || data.roomNumber,
      buildingId: data.building?.value && Number(data.building.value),
      customerId: customerId,
      emailNotification: data.termsAndConditions,
      passwordConfirmation: data.password,
      phoneNotification: data.termsAndConditions,
    };

    const parsedValues: any = omit(
      ['building', 'termsAndConditions', 'userId', 'roomNumber'],
      values,
    );

    if (isCsrCreated) {
      update({ account: { ...parsedValues } });
    } else if (isRegistering || hasExistingData) {
      create(parsedValues);
    } else {
      update(parsedValues);
    }
  };

  const isLoading = saveQueryLoading || isPending;

  const disabled = useMemo(
    () => !activeClientUserTypes.length || isLoading,
    [activeClientUserTypes, isLoading],
  );

  const goToLogin = () => {
    if (!isSam) {
      const url = logInRedirect && logInRedirect();

      return window.location.assign(url as string);
    } else {
      return history.push('/login');
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // @ts-ignore
  return (
    <div className="px-2 py-8">
      <ModalWifiEnabled
        isOpen={isWifiEnabledModalOpen}
        onClose={() => {
          authenticate(authenticationParams);
          setIsWifiEnabledModalOpen(false);
          history.push('/home');
        }}
        preWiredKey={preWiredKey}
      />
      <ModalAccountFound
        isOpen={isAccountFoundModalOpen}
        onClose={() => {
          setValue('username', '', { shouldValidate: true, shouldDirty: true });
          setIsAccountFoundModalOpen(false);
        }}
        email={getValues('username') || ''}
      />
      {isRegistering ? (
        <div className="mb-6">
          <h1 className="text-3xl antialiased font-normal">Create Account</h1>
          <div className="text-sm antialiased font-thin">
            Already have an account?{' '}
            <Button layout="link" size="noPadding" onClick={() => goToLogin()}>
              Sign in
            </Button>
          </div>
        </div>
      ) : (
        <div className="mb-6">
          <h1 className="text-3xl antialiased font-normal">Complete Profile</h1>
        </div>
      )}
      <form>
        <div className="mt-8">
          {isRegistering && (
            <>
              <Label className="my-4">
                <span>
                  Email Address<span className="text-red-600">*</span>
                </span>
                <Input
                  className="my-2"
                  {...register('username')}
                  placeholder="Email Address"
                  readOnly={usernameReadOnly}
                  type="text"
                  {...(isValid('username') ? {} : { valid: false })}
                />
                <HelperText valid={isValid('username')}>{errors.username?.message}</HelperText>
              </Label>
              <Label className="my-4">
                <span>
                  {passwordLabel}
                  <span className="text-red-600">*</span>
                </span>
                <Input
                  className="my-2"
                  {...register('password')}
                  placeholder={`Create a ${passwordLabel}`}
                  type="password"
                  {...(isValid('password') ? {} : { valid: false })}
                />
                <HelperText valid={isValid('password')}>
                  {errors.password?.message && capitalizeFirstLetter(errors.password?.message)}
                </HelperText>
              </Label>
            </>
          )}
          {(hasMultipleSites || roomBasedPanEnabled) && (
            <>
              <Label className="my-4">
                <span>
                  Building<span className="text-red-600">*</span>
                </span>
                <Controller
                  name="building"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      className="my-2"
                      options={formattedBuildings}
                      placeholder="Select A Building"
                      {...(isValid('building', true) ? {} : { valid: false })}
                    />
                  )}
                />
                <HelperText valid={isValid('building', true)}>
                  {errors?.building?.value?.message}
                </HelperText>
                <HelperText>
                  <div>
                    If you do not see your building listed, please{' '}
                    <Link to={'/support'}>contact support</Link>.
                  </div>
                </HelperText>
              </Label>
            </>
          )}
          {roomBasedPanEnabled && (
            <Label className="my-4">
              <span>
                Suite and/or Room #<span className="text-red-600">*</span>
              </span>
              <Controller
                name="pan_room"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={!panRoomOptions.length}
                    isLoading={panRooms?.isPending}
                    className="my-2"
                    noOptionsMessage={() => 'Room not found'}
                    options={panRoomOptions.map(({ label, value }) => ({
                      label,
                      value: `${value}`,
                    }))}
                    placeholder={
                      !getValues('building') ? 'First Select A Building' : 'Select A Room'
                    }
                    {...(isValid('pan_room', true) && isValid('pan_room', false)
                      ? {}
                      : { isValid: false })}
                  />
                )}
              />
              <HelperText valid={isValid('pan_room', true) && isValid('pan_room', false)}>
                {(errors?.pan_room?.value?.message as string) ||
                  (errors?.pan_room?.message as string)}
              </HelperText>
            </Label>
          )}
          {!userInfo.group && activeClientUserTypes.length !== 1 && (
            <>
              <div role="radiogroup" aria-labelledby="account-type">
                <p className="my-4 text-sm">
                  Pick an Account Type<span className="text-red-600">*</span>
                </p>
                {activeClientUserTypes.map(({ name }, key) => (
                  <div key={key}>
                    <Label className="mb-2" radio>
                      <Input {...register('accountType')} type="radio" value={name} role="radio" />
                      <span className="capitalize ml-2">{name}</span>
                    </Label>
                  </div>
                ))}
              </div>
              {!activeClientUserTypes.length && (
                <HelperText valid={false}>
                  There are no defined Account Types, please{' '}
                  <Link to={'/support'} className="text-blue-500">
                    contact support
                  </Link>
                  .
                </HelperText>
              )}
              <HelperText valid={isValid('accountType')}>
                {errors?.accountType?.message as string}
              </HelperText>
            </>
          )}

          <div className="my-6">
            <Label check>
              <Input
                {...register('termsAndConditions')}
                type="checkbox"
                {...(isValid('termsAndConditions') ? {} : { valid: false })}
              />
              <p className="px-2 text-xs">
                By checking box, you agree to{' '}
                <a
                  href={ApogeeEuaPdf}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="underline border-2 border-transparent focus:border-blue-700"
                >
                  Terms and Conditions
                </a>
              </p>
            </Label>
            <HelperText valid={isValid('termsAndConditions')} className="flex">
              {errors.termsAndConditions?.message}
            </HelperText>
          </div>
          {!!Object.keys(userInfoFormErrors).filter((key) => !UserInfoFormFields.includes(key))
            .length && (
            <HelperText valid={false}>
              {Object.keys(userInfoFormErrors)
                .filter((key) => !UserInfoFormFields.includes(key))
                .map((key) => (
                  <div key={key} className="mb-5 block">
                    <b>{key}</b>
                    <ul>
                      {(!Array.isArray(userInfoFormErrors[key as keyof typeof userInfoFormErrors])
                        ? [userInfoFormErrors[key as keyof typeof userInfoFormErrors]]
                        : userInfoFormErrors[key as keyof typeof userInfoFormErrors]
                      ).map((message, i) => (
                        <li key={i}>{message}</li>
                      ))}
                    </ul>
                  </div>
                ))}
            </HelperText>
          )}
        </div>
        <div className="flex flex-row-reverse justify-between gap-16">
          <Button
            className="flex-grow font-black"
            disabled={disabled}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {isRegistering ? 'Create Account' : 'Complete Profile'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserInfoSimpleForm;
