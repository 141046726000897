import { FCWithChildren } from '@types';
import React, { useEffect, useState } from 'react';

import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import useLayoutContext from '@hooks/useLayoutContext';
import {
  DEVICE_NAME_REGEX,
  DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_DEVICE_NAME_REGEX,
  SAM_DEVICE_NAME_VALIDATION_MESSAGE,
} from '../../../../constants';

import {
  ICampusDevice,
  addCampusDevice,
  getGroups,
  getPlaceOnNetwork,
  updateCampusDevice,
  getCampusDeviceTypes,
  INetworkPlace,
  ICampusDeviceType,
} from 'sdk-apogee';

import {
  Button,
  HelperText,
  Input,
  Label,
  MACAddressInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UploadMacAddressImage,
} from '@components';
import className from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type AddDeviceModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  device: ICampusDevice | undefined;
};

type FormData = {
  macAddress: string;
  name: string;
  deviceType: { id: number; name: string };
  network: { id: number; name: string };
  group: { id: number; name: string };
};

type Params = {
  name: string;
  campusDeviceTypeId: number;
  deviceGroupId: number;
  networkId: number;
  macAddress: string;
};

const CampusDeviceModal: FCWithChildren<AddDeviceModalProps> = ({ closeModal, device, isOpen }) => {
  const { isSam, pskEnabled } = useLayoutContext();
  const queryClient = useQueryClient();
  const [macAddressModalOpen, setMacAddressModalOpen] = useState(false);

  const { addToast } = useToasts();

  const { data: networkPlaceOptions = [] } = useSuspenseQuery<INetworkPlace[]>({
    queryKey: ['campus-location'],
    queryFn: getPlaceOnNetwork,
  });
  const { data: deviceTypeOptions = [] } = useSuspenseQuery<ICampusDeviceType[]>({
    queryKey: ['campus-device-types'],
    queryFn: getCampusDeviceTypes,
  });
  const { data: groupOptions } = useSuspenseQuery({
    queryKey: ['campusGroups'],
    // @ts-ignore
    queryFn: () => getGroups(),
  });

  const { mutate: createDevice, ...createQueryInfo } = useMutation({
    mutationFn: (values: Params) => addCampusDevice(values),
    onSuccess: () => {
      addToast('Device added successfully', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      closeModal();
    },
    onError: (apiError: any) => {
      const formErrors = apiError?.errors;
      const formError = apiError?.error;
      if (formErrors && formErrors.constructor === Object) {
        addToast('Check one or more fields', { appearance: 'error' });
        Object.keys(formErrors).forEach(function (key) {
          // @ts-ignore
          setError(key, { type: 'server', message: formErrors[key].join('. ') });
        });
      } else if (formError) {
        addToast(formError, { appearance: 'error' });
      } else {
        addToast('An unknown error occurred trying to create the device', { appearance: 'error' });
      }
    },
  });

  const { mutate: updateDevice, isPending } = useMutation({
    mutationFn: (values: Params) => updateCampusDevice((device as ICampusDevice)?.id, values),
    onSuccess: () => {
      addToast('The device was updated successfully', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      closeModal();
    },
    onError: (apiError: any) => {
      const formErrors = apiError?.errors;
      const formError = apiError?.error;
      if (formErrors && formErrors.constructor === Object) {
        addToast('Check one or more fields', { appearance: 'error' });
        Object.keys(formErrors).forEach(function (key) {
          // @ts-ignore
          setError(key, { type: 'server', message: formErrors[key].join('. ') });
        });
      } else if (formError) {
        addToast(formError, { appearance: 'error' });
      } else {
        addToast('An unknown error occurred trying to update the device', { appearance: 'error' });
      }
    },
  });

  const onKeyDownImageHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {};

  const isValid = (input: string): boolean => !(errors as any)[input]?.message;

  const populateInputs = () => {
    const { name, deviceGroupId, networkId, deviceType, macAddress } = device as ICampusDevice;

    const group = groupOptions?.groups.find((groupItem) => groupItem.id === deviceGroupId);
    const networkPlace = networkPlaceOptions.find((network) => network.id === networkId);
    const type = deviceTypeOptions.find((typeItem) => typeItem.name.includes(deviceType));
    const digits = macAddress.split(':');

    // @ts-ignore
    digits.forEach((digit, idx) => setValue(`block-${idx + 1}`, digit.toUpperCase()));

    setValue('name', name);
    // @ts-ignore
    setValue('group', group);
    // @ts-ignore
    setValue('network', networkPlace);
    // @ts-ignore
    setValue('deviceType', type);
  };

  useEffect(() => {
    if (device) {
      populateInputs();
      // } else {
      //   [null,null,null,null,null,null].forEach((digit, idx) => setValue(`block-${idx + 1}`, digit));
      //   setValue('name', null);
      //   setValue('group', null);
      //   setValue('network', null);
      //   setValue('deviceType', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const CAMPUS_DEVICE_SCHEMA = yup.object().shape({
    macAddress: yup
      .string()
      .required('MAC Address is required')
      .test('macAddressValidation', 'Please enter a valid MAC Address', (val) =>
        val ? /^(([A-Fa-f0-9]{2}[\W]?){5}[A-Fa-f0-9]{2}[,]?)+$/.test(val) : false,
      ),
    name: yup
      .string()
      .required('Device Name is required')
      .min(1)
      .test(
        'valid',
        isSam ? SAM_DEVICE_NAME_VALIDATION_MESSAGE : DEVICE_NAME_VALIDATION_MESSAGE,
        (val) => (isSam ? SAM_DEVICE_NAME_REGEX.test('' + val) : DEVICE_NAME_REGEX.test('' + val)),
      ),
    deviceType: yup.object().required('Device Type is required'),
    network: yup.object(),
    group: yup.object().required('Group is required'),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
  } = useForm<FormData>({
    // @ts-ignore
    resolver: yupResolver(CAMPUS_DEVICE_SCHEMA),
  });

  const macAddress = watch('macAddress');

  const onSubmit = (data: FormData) => {
    const selectedValues = {
      name: data.name,
      deviceGroupId: data.group.id,
      networkId: data.network?.id,
      campusDeviceTypeId: data.deviceType.id,
      macAddress: data?.macAddress?.match(/\w{2}/g)?.join(':') || '',
    };

    device ? updateDevice(selectedValues) : createDevice(selectedValues);
  };

  const isLoading = createQueryInfo.isPending || isPending;

  const modalClass = className('max-h-screen-90 w-1/3', macAddressModalOpen ? 'hidden' : '');
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} customWebClass={modalClass}>
      <ModalHeader>
        <span className="font-semibold">{device ? 'Edit ' : 'Add Individual'} Device</span>
      </ModalHeader>
      <ModalBody>
        <Label className="my-4 w-full">
          <div className="flex w-full justify-center">
            <div className="flex flex-col items-start max-w-90">
              <span className="text-xs">
                Enter MAC address<span className="text-red-600">*</span>
              </span>
              <MACAddressInput control={control} register={register} clearInput={!macAddress} />
              {!pskEnabled && macAddress?.match(/^.[2367ABEF]/i) && (
                <div>
                  <HelperText warning>Random/Private MAC address entered</HelperText>
                </div>
              )}
              <HelperText valid={isValid('macAddress')}>{errors.macAddress?.message}</HelperText>
              <div className="text-xs w-full flex justify-end items-center pt-4 pb-2 text-gray-700">
                <UploadMacAddressImage
                  setValue={setValue}
                  onKeyDown={onKeyDownImageHandler}
                  closeModalCallback={() => setMacAddressModalOpen(false)}
                  openModalCallback={() => setMacAddressModalOpen(true)}
                />
              </div>
            </div>
          </div>
        </Label>

        <Label className="my-4">
          <span className="text-xs">
            {device ? 'Group' : 'Add to Group'}
            <span className="text-red-600">*</span>
          </span>
          <Controller
            name="group"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="my-1"
                placeholder="Group"
                options={groupOptions?.groups}
                {
                  /* @ts-ignore */ ...{}
                }
                getOptionValue={({ id }) => id.toString()}
                getOptionLabel={({ name }) => name}
                components={{ IndicatorSeparator: () => null }}
              />
            )}
          />
          <HelperText valid={isValid('group')}>{errors.group?.message}</HelperText>
        </Label>
        <Label className="my-4">
          <span className="text-xs">
            Device Type<span className="text-red-600">*</span>
          </span>
          <Controller
            name="deviceType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="my-1"
                placeholder="Device Type"
                options={deviceTypeOptions}
                {
                  /* @ts-ignore */ ...{}
                }
                getOptionValue={({ id }) => id.toString()}
                getOptionLabel={({ name }) => name}
                components={{ IndicatorSeparator: () => null }}
              />
            )}
          />
          <HelperText valid={isValid('deviceType')}>{errors.deviceType?.message}</HelperText>
        </Label>

        <Label className="my-4">
          <span className="text-xs">
            Device Name<span className="text-red-600">*</span>
          </span>
          <Input
            className="my-1"
            {...register('name')}
            type="text"
            readOnly={device && isSam}
            style={device && isSam ? { color: '#808080' } : {}}
          />
          <HelperText valid={isValid('name')}>{errors.name?.message}</HelperText>
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button
          block
          className="font-black"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {device ? 'Save' : 'Add Device'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CampusDeviceModal;
