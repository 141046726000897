import { FCWithChildren } from '@types';
import React, { useEffect, useMemo, useState } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import Select from 'react-select';

import classNames from 'classnames';

import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICustomer, getCustomers, SCHOOL_DOMAIN, PROPERTY_DOMAIN } from 'sdk-apogee';

import { getDomain, buildUrl } from '@utils';
import useLayoutContext from '@hooks/useLayoutContext';

import { Button, HelperText, Input, Label } from '@components';

type OptionsOrGroups<Option, Group> = (Option | Group)[];

type SchoolFormProps = {
  isMobile: boolean;
  setCampusType: (value) => void;
};

type Dropdown = {
  label?: string;
  value?: string;
};

type FormData = {
  campus: string | null;
  customer: Dropdown | null;
  stateAndCity: {
    label: string;
    value: number | undefined;
  }[];
};

const SCHEMA = yup.object().shape({
  campus: yup.string().required(),
  customer: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string().required(),
    })
    .required(),
});

const CURRENT_DOMAIN = getDomain();

const getCustomersForDropdown = ({ displayName, subdomain, url }: Partial<ICustomer>) => ({
  label: displayName,
  value: subdomain ? subdomain : url,
});

const getStateAndCityForDropdown = ({ id, state, city }: Partial<ICustomer>) => ({
  label: `${city}, ${state}`,
  value: id,
});

const PROP_DOM = PROPERTY_DOMAIN ? PROPERTY_DOMAIN : '';
const IS_PROPERTY_DOMAIN = CURRENT_DOMAIN.toLowerCase() === PROP_DOM.toLowerCase();

const SchoolForm: FCWithChildren<SchoolFormProps> = ({ isMobile, setCampusType }) => {
  // @ts-ignore
  const [properties, setProperties] = useState<OptionsOrGroups<Dropdown, any>>(null);
  const { data = [] } = useSuspenseQuery<ICustomer[]>({
    queryKey: ['customers'],
    queryFn: getCustomers,
  });

  const schools = useMemo(
    () => data.filter(({ type }) => type === 'School').map(getCustomersForDropdown),
    [data],
  );

  const { changeType } = useLayoutContext();

  const sortStateAlphabetically = (itemA: ICustomer, itemB: ICustomer) => {
    let textA = `${itemA?.state} ${itemA?.city}`.toUpperCase() as string;
    let textB = `${itemB?.state} ${itemB?.city}`.toUpperCase() as string;

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };

  const stateAndCity = useMemo(() => {
    const orderedArray = data
      .filter(({ type }) => type === 'Property')
      .sort(sortStateAlphabetically);

    const removeDuplicates = (item: ICustomer, index: number) =>
      orderedArray.findIndex(({ state, city }) => state === item.state && city === item.city) ===
      index;

    return orderedArray.filter(removeDuplicates).map(getStateAndCityForDropdown);
  }, [data]);

  const { control, getValues, handleSubmit, register, setValue, watch, formState } =
    useForm<FormData>({
      defaultValues: {
        campus: IS_PROPERTY_DOMAIN ? 'off' : 'on',
      },
      mode: 'onBlur',
      reValidateMode: 'onChange',
      // @ts-ignore
      resolver: yupResolver(SCHEMA),
    });

  const errors = formState.errors;
  const watchCampus = watch('campus');
  const watchStateAndCity = watch('stateAndCity');

  useEffect(() => {
    setValue('customer', null);
  }, [getValues, setValue, watchCampus]);

  const getProperties = ({ state, city }: { state: string; city: string }) =>
    data.filter((item) => item.state === state && item.city === city && item.type === 'Property');

  const sortPropertiesAlphabetically = (itemA: ICustomer, itemB: ICustomer) => {
    const textA = `${itemA?.displayName}`.toUpperCase() as string;
    const textB = `${itemB?.displayName}`.toUpperCase() as string;

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };

  useEffect(() => {
    if (watchStateAndCity) {
      const [city, state] = (watchStateAndCity as any)?.label?.split(', ');

      setValue('customer', null);

      const newProperties = getProperties({ state, city })
        .sort(sortPropertiesAlphabetically)
        .map(getCustomersForDropdown);

      setProperties(newProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStateAndCity]);

  useEffect(() => {
    if (watchCampus) {
      setCampusType(watchCampus);
    }
  }, [setCampusType, watchCampus]);

  useEffect(() => {
    const isSchoolDomain = SCHOOL_DOMAIN?.toLowerCase() === CURRENT_DOMAIN.toLowerCase();
    const isPropertyDomain = PROPERTY_DOMAIN?.toLowerCase() === CURRENT_DOMAIN.toLowerCase();

    if (isSchoolDomain) {
      setValue('campus', 'on');
      changeType('School');
    } else if (isPropertyDomain) {
      setValue('campus', 'off');
      changeType('Property');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOff = watchCampus === 'off';

  const isValid = (input: string) => {
    return !(errors as any)[input]?.message;
  };

  const isValidDropdownValue = (input: string) => !(errors as any)[input]?.value?.message;

  const onSubmit = ({ customer }: FormData) => {
    customer?.value?.toLowerCase().startsWith('http:') ||
    customer?.value?.toLowerCase().startsWith('https:')
      ? window.location.replace(customer?.value)
      : window.location.replace(buildUrl(customer?.value));
  };

  const typeStyle = classNames('flex justify-center', {
    'flex-col items-start': isMobile,
    'items-center space-x-4': !isMobile,
  });

  return (
    <form>
      <div className="text-center">
        <div className={typeStyle}>
          <Label className="my-2" radio>
            <Input
              {...register('campus')}
              type="radio"
              value="on"
              {...(isValid('campus') ? {} : { valid: false })}
            />
            <span className="ml-2">On Campus</span>
          </Label>
          <Label className="my-2" radio>
            <Input
              {...register('campus')}
              type="radio"
              value="off"
              {...(isValid('campus') ? {} : { valid: false })}
            />
            <span className="ml-2">Off Campus</span>
          </Label>
        </div>
        <HelperText valid={isValid('campus')}>{errors.campus?.message}</HelperText>
      </div>
      {isOff ? (
        <>
          <Label className="my-4">
            <span>City</span>
            <Controller
              name="stateAndCity"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-1"
                  options={stateAndCity}
                  placeholder="- Select One -"
                  components={{ IndicatorSeparator: () => null }}
                />
              )}
            />
            <HelperText valid={isValidDropdownValue('customer')}>
              {errors.customer?.value?.message}
            </HelperText>
          </Label>
          <Label className="my-4">
            <span>Property</span>
            <Controller
              name="customer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-1"
                  options={properties}
                  isDisabled={!watchStateAndCity}
                  placeholder="- Select One -"
                  components={{ IndicatorSeparator: () => null }}
                />
              )}
            />
            <HelperText valid={isValidDropdownValue('customer')}>
              {errors.customer?.value?.message}
            </HelperText>
          </Label>
        </>
      ) : (
        <Label className="my-4">
          <span>School</span>
          <Controller
            name="customer"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="my-1"
                options={schools}
                placeholder="- Select One -"
                components={{ IndicatorSeparator: () => null }}
              />
            )}
          />
          <HelperText valid={isValidDropdownValue('customer')}>
            {errors.customer?.value?.message}
          </HelperText>
        </Label>
      )}
      <Button block onClick={handleSubmit(onSubmit)}>
        Submit
      </Button>
    </form>
  );
};

export default SchoolForm;
