import { colorLuminance } from './utils';

export default {
  primary: '#02003F',
  secondary: '#f50650',
  negative: '#e45b78',
  positive: '#A3D9B1',
  textPrimary: '#333',
  backgroundPrimary: '#02003F',
  backgroundSecondary: '#E5E5E5',
  backgroundPrimaryLighten: colorLuminance('#02003F', -1),
  backgroundSecondaryLighten: colorLuminance('#E5E5E5', -0.2),
  backgroundHover: '#02003F',
};
