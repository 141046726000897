import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Layout, NotFound } from '@components';
import { AppContext } from '@contexts/AppContext';
import { ErrorContext } from '@contexts/ErrorContext';
import ROUTES from './routes';
import { FCWithChildren } from '@types';
import useLayoutContext from '@hooks/useLayoutContext';

const Router: FCWithChildren<{}> = () => {
  const { isAuthenticated } = useContext(AppContext);
  const { logInRedirect } = useLayoutContext();

  const loginUrl = logInRedirect && logInRedirect();

  if (!isAuthenticated) {
    localStorage.setItem('confirmPackageExpiration', 'false');
  }

  return (
    <ErrorContext>
      <Switch>
        {ROUTES.map(({ auth, isBoldynRoute, component, path, noFooter, ...rest }) => {
          const Component: React.FC<any> = component;
          return (
            <Route
              key={path as string}
              path={path}
              {...rest}
              render={(props) => {
                if (auth && !isAuthenticated) return <Redirect to={loginUrl as string} />;
                return (
                  <Layout
                    publicRoute={!auth}
                    isBoldynRoute={!auth && isBoldynRoute}
                    noFooter={noFooter}
                  >
                    <Component {...props} />
                  </Layout>
                );
              }}
            />
          );
        })}
        <Route
          render={() => (
            <Layout publicRoute isBoldynRoute>
              <NotFound />
            </Layout>
          )}
        />
      </Switch>
    </ErrorContext>
  );
};

export default Router;
